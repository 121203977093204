/* @import '~antd/dist/antd.css'; */

#app {
  height: 100%;
}

.ant-page-header {
  padding-inline: 0;
}

/* form {
  margin-left: 1em !important;
} */

.logo:before {
  /* content: url('../images/logo.svg'); */
  width: 2.5rem;
  margin-right: 0.5rem;
}

.logo {
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  color: #fff;
  /* padding-left: 2.5rem; */
  /* margin-top: 0.4rem; */
}

/* main.ant-layout-content.main-layout {
  padding-left: 1em;
} */

aside {
  margin-right: 1em;
}

footer {
  margin-top: 1em;
}

.ant-layout-sider {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  padding-right: 0.5em;
}

.ant-layout-sider .ant-page-header-heading-extra button {
  border: none;
  box-shadow: none;
  margin: 0 !important;
  cursor: pointer;
}

/* .ant-layout-sider .ant-page-header-heading-extra button:hover,
.ant-layout-sider .ant-page-header-heading-extra button:focus {
  outline: none;
} */

.ant-layout {
  width: auto !important;
  max-width: 100%;
  min-height: 100%;
  background: #fff;
}

/* .ant-layout > .ant-layout-content { */
/* padding: 25px 50px !important; */
/* padding: 0 35px; */
/* background: #fff; */
/* } */

/* main.ant-layout-content.main-layout { */
/* padding-right: 0 !important; */
/* } */

.ant-layout-header {
  /* background: #fff; */
  /* background: #3178c6; */
  /* box-shadow: 0 2px 8px #f0f1f2; */
  padding-inline: 1em !important;
}

main header.ant-layout-header {
  background: #fff !important;
  color: #fff !important;
}

.ant-layout-header h1 {
  color: #fff;
}

/* .ant-page-header { */
/* padding: 0 !important; */
/* } */

.ant-divider-horizontal {
  margin-top: 5px !important;
  position: sticky;
  top: 3rem;
  z-index: 1;
}

.userMenu {
  text-align: right;
}

.userMenu button {
  margin-right: 8px;
}

/* User profile menu */
.user-profile-menu-link {
  display: block;
  color: #fff;
}

.user-profile-menu-link:hover,
.user-profile-menu-link:active {
  color: #f0f1f2;
}

/* .ant-page-header.ant-page-header-ghost { */
/* padding-left: 0;
  padding-right: 0; */
/* } */

/* .ant-layout-header {
  height: 20vh;
} */

/* Vertical align */
.ant-row.ant-row-space-around.ant-row-middle {
  height: 80vh;
}

/* Login form */
.login-form-forgot {
  float: right;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.button-block {
  width: 100%;
}

.ant-upload.ant-upload-select.ant-upload-select-text {
  width: 100%;
  margin-bottom: 20px;
}

/* .user-profile-menu-link {
  display: block;
} */

/* Loader */
.ant-spin.ant-spin-spinning {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  background: #fff !important;
  height: 100vh !important;
  color: #0096dc;
}

/* .ant-layout-footer {
  height: 20vh;
} */

/* .ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
} */

/* Chart */
.chart-title {
  margin-top: 25px;
}

.ant-col.chart {
  margin-top: -7%;
}

/* Drag Upload */

.ant-upload.ant-upload-drag {
  height: 25em;
  background: #fff;
  /* border: none; */
}

/* Menu */
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item:hover {
  /* background-color: #fafafa !important; */
  background-color: #e5f4fb !important;
  color: #0096dc !important;
}

button.ant-btn {
  margin: 10px 5px 10px 0px;
}

.ant-page-header-heading-extra button.ant-btn {
  margin: 0 0 0 12px;
}

/* Data tree */
span.ant-tree-switcher {
  display: none;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  margin: 0px 0px 0 8px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

/**
  Card image
*/

/* .card-image { */
/* padding-top: 100%; */
/* } */

.card-image .ant-card-body {
  padding: 0;
  overflow: hidden;
  border-radius: 8px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-image .ant-image img,
.card-image .ant-image .ant-image-mask,
.ant-image .ant-image-img {
  padding: 0;
  /* border: 1px solid #d9d9d9; */
  border-radius: 6px;
  /* z-index: 9999; */
}

.card-image ul.ant-card-actions {
  position: absolute;
  bottom: 5px;
  width: 100%;
  background: none !important;
  border: none !important;
  /* background: rgba(0, 0, 0, 0.5); */
  z-index: 9999;
}

.card-image ul.ant-card-actions li {
  margin: 0;
  border: none !important;
}

.card-image ul.ant-card-actions li button.ant-btn {
  margin: 0;
}

/* .card-image ul.ant-card-actions li button.ant-btn.ant-btn-circle { */
/* background: #fff; */
/* color: #00b96b; */
/* background: rgba(0, 0, 0, 0.5); */
/* color: #fff; */
/* } */

/* footer.ant-layout-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
} */

.directorylist-button {
  display: none;
}

@media (min-width: 577px) {
  .ant-page-header-heading-extra .action-list-directory,
  .ant-page-header-heading-extra .action-delete-selected,
  .ant-page-header-heading-extra .action-clear-selection,
  .ant-page-header-heading-extra .action-delete-directory,
  .ant-page-header-heading-extra .action-close-modal,
  .ant-page-header-heading-extra .action-past-selected,
  .ant-layout-header.action-list .action-list-directory,
  .ant-layout-header.action-list .action-create-directory,
  .ant-layout-header.action-list .action-upload {
    display: none !important;
  }
}

@media (max-width: 576px) {
  main.ant-layout-content {
    /* background-color: #f0f2f5; */
    /* min-height: 90vh; */
    margin-top: 4em;
  }

  .ant-modal-body .ant-layout.ant-layout-has-sider main.ant-layout-content {
    /* background-color: #f0f2f5; */
    /* min-height: 90vh; */
    margin-top: 0 !important;
  }

  .ant-layout-sider.ant-layout-sider-light {
    max-width: 100% !important;
    width: 100% !important;
    position: fixed;
    left: 0;
    right: 0;
    padding-inline: 1em;
    z-index: 2;
    border: none;
    top: 4em;
  }

  .ant-modal section.ant-layout.ant-layout-has-sider {
    flex-direction: column;
  }

  .ant-modal
    section.ant-layout.ant-layout-has-sider
    section.ant-layout.css-dev-only-do-not-override-2u2vot {
    display: contents;
  }

  .ant-modal .ant-layout-sider.ant-layout-sider-light {
    /* max-width: 100% !important;
    width: auto !important; */
    position: sticky;
    align-self: flex-start;
    position: sticky;
    display: flex;
    align-self: flex-start;
    top: -1em;
    flex: 0 0 0 !important;
    padding-inline: 0 !important;
    flex-flow: column;
    /* left: 0;
    right: 0;
    padding-inline: 1em;
    z-index: 2;
    border: none;
    top: 0;
    margin: 1em;
    scroll-margin-top: 0 !important; */
  }

  .data-tree-container {
    display: none !important;
  }

  .ant-tree-list-holder-inner {
    background: #fff;
    width: 100%;
    position: absolute;
    left: 0;
    top: -3px;
  }

  header.ant-layout-header.action-list {
    display: none !important;
    /* top: 7em; */
  }

  /* .logo {
    font-size: 1.1em;
  } */

  main.ant-layout-content.main-layout {
    margin-left: 0;
  }

  header.ant-layout-header {
    position: fixed;
    width: 100%;
    z-index: 2;
  }

  /* main section section {
    margin-top: 8em;
  } */

  .directorylist-button {
    display: block;
  }

  /* .ant-layout-sider .ant-page-header {
    width: 100%;
  } */

  /* .ant-page-header-content {
    position: fixed;
    height: 100%;
    width: 100% !important;
    top: 7em;
    left: 1em;
    right: 1em;
    background: #fff;
    display: none;
  } */

  .ant-page-header-heading-extra .ant-btn-icon-only {
    color: #000 !important;
    background: none;
  }

  .ant-col.thumbnails {
    display: flex;
    flex-direction: row;
  }

  .line-chart {
    max-height: 15rem !important;
  }
}

.LazyLoad {
  position: relative;
  /* height: 250px; */
  height: max-content !important;
}

.ant-skeleton.ant-skeleton-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ant-skeleton-image {
  width: 100% !important;
  height: 100% !important;
}

header.ant-layout-header.action-list {
  background: none;
  height: auto;
  padding: 0 !important;
}

/* body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
} */

/* section.ant-layout,
main.ant-layout-content {
  height: 100% !important;
}

footer {
  margin-top: auto;
} */

/* .ant-modal {
  display: flex;
  min-height: 100%;
  overflow: hidden;
}

.ant-modal-content {
  flex-grow: 1;
} */

.ant-tree-title span.anticon.anticon-loading.anticon-spin {
  position: absolute;
  right: 1em;
  display: none;
}

.ant-modal button.ant-modal-close {
  display: none;
}

.thumbnail.editable button.ant-btn,
.thumbnail.editable .ant-divider-with-text {
  margin: 0;
}

.thumbnail.editable .ant-image-img,
.thumbnail .image-not-found img,
.thumbnail .ant-image-mask {
  border-radius: 8px;
  width: 100%;
}

/* .image-not-found {
  background-image: url('../public/image.svg');
} */

.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before,
.ant-tree.ant-tree-directory .ant-tree-treenode:before {
  border-radius: 6px;
  padding: 1em;
}

/* .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper,
.ant-tree.ant-tree-directory
  .ant-tree-treenode
  .ant-tree-node-content-wrapper:hover {
  border-radius: 6px !important;
  padding: 1em;
} */

.ant-tree .ant-tree-treenode {
  align-items: center;
  padding: 0.2em;
}

.ql-editor {
  min-height: 10rem;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ant-form-item-has-error .ql-toolbar.ql-snow,
.ant-form-item-has-error .ql-container.ql-snow {
  border-color: #ff4d4f;
}

.ant-modal {
  top: 0 !important;
  max-width: 100%;
  height: 100%;
  padding: 1em;
}

.ant-modal.resource-modal .ant-modal-content {
  /* position: fixed; */
  width: 100%;
  height: auto;
  min-height: 100%;
  max-height: 100%;
  padding: 1em;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 0.5em;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ccc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .ant-tree-node-selected,
.ant-btn-primary,
.ant-dropdown-link,
.ant-btn-dashed,
.ant-dropdown-menu-item-selected,
.ant-btn-link span {
  color: #fff !important;
} */

.ant-btn-dangerous,
.ant-dropdown-link:hover {
  color: #fff !important;
}

.ant-dropdown-menu-item-selected {
  background: #e5f4fb !important;
}

.infinite-scroll .ant-row {
  row-gap: 1rem !important;
}

.ant-btn-default.ant-btn-dangerous {
  color: #ff4d4f !important;
}

.order-info .ant-statistic-content {
  font-size: 1rem !important;
}

.order-info ul.order-items {
  list-style: none;
  padding: 0;
}

.order-info ul.order-items li {
  padding: 0.3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-info ul.order-items li:nth-child(even) {
  background: rgba(0, 0, 0, 0.04);
  /* border-radius: 6px; */
}

.order-info ul.order-items li span {
  text-align: right;
}

/* ul.order-items li:hover {
  cursor: pointer;
  background: #e5f4fb;
  border-bottom: 0.0625rem solid #0096dc !important;
  color: #0096dc;
} */

.ant-statistic {
  margin-bottom: 0.5rem !important;
}

@media print {
  .ant-layout-header,
  .ant-layout-sider,
  .ant-page-header-heading-extra {
    display: none;
  }
  /* .printme  { display: block;} */
}

button.ant-btn,
.ant-form-item .ant-form-item-label > label {
  text-transform: capitalize;
}

/* NOTIFICATIONS */
.notifications-icon {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
}

.notifications-icon .ant-badge .ant-badge-count {
  top: 1rem;
  left: 1rem;
  inset-inline-end: auto;
}

.notifications .ant-list-item-meta-avatar {
  margin: 0 !important;
}

.notifications li.ant-list-item {
  flex-direction: column !important;
  padding: 1rem !important;
  position: relative;
}

.notifications li.ant-list-item:hover {
  background: #e5f4fb;
  cursor: pointer;
}

.notifications .ant-drawer-body {
  padding: 0 !important;
}

.notifications button.ant-btn {
  margin: 0;
}

.notifications .ant-list-item-action {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  top: 1rem;
  right: 0;
}

.notifications .ant-list .ant-list-item .ant-list-item-meta {
  width: 100%;
}

.notifications .ant-list-item-meta-description {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.ant-drawer-content-wrapper {
  max-width: 100% !important;
}

/* .ant-spin-nested-loading > div > .ant-spin {
  max-height: 100vh !important;
} */

.ant-spin-nested-loading > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ant-spin-nested-loading {
  height: 100% !important;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}

/* CHARTS */
.line-chart {
  height: max-content !important;
  width: 100% !important;
  max-height: calc(100vh - 300px);
}

/* main.ant-layout-content.main-layout {
  position: sticky;
  top: 0;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
} */

.ant-statistic.red .ant-statistic-title,
.ant-statistic.red .ant-statistic-content {
  color: red;
}

.ant-statistic.green .ant-statistic-title,
.ant-statistic.green .ant-statistic-content {
  color: green;
}

video {
  display: block;
  object-fit: cover;
}

form .ant-page-header.ant-page-header-ghost {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}

header button span.ant-btn-icon {
  padding-right: 0.2rem;
}

.ant-drawer-body form {
  margin-top: 1rem;
}
